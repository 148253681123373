import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import UPS from '../../src/Icons/CarrierUPS';
import FedEx from '../../src/Icons/CarrierFedex';
import USPS from '../../src/Icons/CarrierUSPS';
import DefaultCarrier from '../../src/Icons/CarrierDefault';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "logos"
    }}>{`Logos`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import IconName from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <UPS />\n  <FedEx />\n  <USPS />\n  <DefaultCarrier />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      UPS,
      FedEx,
      USPS,
      DefaultCarrier,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <UPS mdxType="UPS" />
    <FedEx mdxType="FedEx" />
    <USPS mdxType="USPS" />
    <DefaultCarrier mdxType="DefaultCarrier" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      